import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import Masonry from 'react-masonry-css';

import { BREAKPOINTS_MAP } from 'config/style';
import { macroActivityShape } from 'shapes/businessActivities';

import MacroActivityCard from 'react/business/businessActivities/card/MacroActivityCard';
import withMacroActivities from 'react/business/businessActivities/connect/withMacroActivities.connect';

import classNames from './macroActivities-list.module.scss';

// max-width => col count.
const breakpointsCol = {
  default: 3,
  [BREAKPOINTS_MAP.tiny]: 1,
  [BREAKPOINTS_MAP.small]: 1,
  [BREAKPOINTS_MAP.medium]: 2,
  [BREAKPOINTS_MAP.large]: 2,
  [BREAKPOINTS_MAP['extra-large']]: 4,
};

const enhancer = compose(
  withMacroActivities,
  memo,
);

const MacroActivitiesList = ({ macroActivities }) => (
  <Masonry
    breakpointCols={breakpointsCol}
    className={classNames.grid}
    columnClassName={classNames.column}
  >
    { Object.values(macroActivities).map(macro => (
      <MacroActivityCard
        key={macro.id}
        macroActivity={macro}
      />
    ))}
  </Masonry>
);

MacroActivitiesList.displayName = 'MacroActivitiesList';

MacroActivitiesList.propTypes = {
  macroActivities: PropTypes.objectOf(macroActivityShape),
};

MacroActivitiesList.defaultProps = {
  macroActivities: {},
};

export default enhancer(MacroActivitiesList);
