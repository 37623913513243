import React, { memo } from 'react';
import { compose } from 'recompose';

import MacroActivitiesList from 'react/business/businessActivities/list/MacroActivitiesList';

const enhancer = compose(
  memo,
);

const ActivitiesPage = () => (
  <div>
    <MacroActivitiesList />
  </div>
);

export default enhancer(ActivitiesPage);
