import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { compose, withHandlers } from 'recompose';
import { formatRoute } from 'react-router-named-routes';
import { withRouter } from 'react-router-dom';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import cn from 'classnames';

import { Icon } from 'antd';
import ROUTES from 'react/routes';
import Button from 'react/generic/button/Button';
import { macroActivityShape, activityShape } from 'shapes/businessActivities';

import connect from 'react/hoc/connectProxy';
import withExpandState from 'react/hoc/withExpandState';

import { selectActivitiesListByMacroActivityId } from 'redux/businessActivities/selectors';

import globalMessages from 'config/global.messages';
import ActivityListItem from './activityListItem/ActivityListItem';

import messages from './macroActivity-card.messages';
import classNames from './macroActivity-card.module.scss';

const withEventHandler = withHandlers({
  onClickSeeMacroActivityProducts: ({ macroActivity, history }) => () => {
    const query = `macroActivityIds=${macroActivity.id}`;
    history.push(`${formatRoute(ROUTES.PRODUCTS)}?${query}`);
  },
});

const enhancer = compose(
  withRouter,
  withExpandState,
  withEventHandler,

  connect(
    (state, props) => ({
      activities: selectActivitiesListByMacroActivityId(state, props.macroActivity.id),
    }),
  ),

  injectIntl,
  memo,
);

const MacroActivityCard = ({
  macroActivity,
  activities,
  // Own props
  isPanelExpanded,
  onTogglePanel,
  onClickSeeMacroActivityProducts,
  intl,
}) => {
  const totalProductCount = macroActivity.productCount || 0;

  return (
    <div className={classNames.card}>
      <div className={classNames.top}>
        <div className={classNames.title}>
          <button
            type="button"
            className={cn('unstyled with-pointer', classNames.titleButton)}
          >
            {macroActivity.name}
          </button>
        </div>
      </div>

      <div className={classNames.divider} />

      <div className={classNames.bottom}>
        <div>
          <div className={classNames.totalProductCount}>
            <FormattedHTMLMessage
              {...messages.TOTAL_PRODUCT_COUNT}
              values={{ count: totalProductCount }}
            />
          </div>
          <div className={classNames.activitiesCount}>
            <FormattedMessage
              {...messages.ACTIVITIES_COUNT}
              values={{ count: activities?.length || 0 }}
            />
          </div>
        </div>
        <div className={classNames.arrow}>
          <button
            type="button"
            className={cn('unstyled with-pointer')}
            onClick={onTogglePanel}
            title={(isPanelExpanded ?
              intl.formatMessage({ ...globalMessages.HIDE_DETAILS })
              : intl.formatMessage({ ...globalMessages.SHOW_DETAILS }))}
          >
            <Icon type={isPanelExpanded ? 'up-circle' : 'down-circle'} />
          </button>
        </div>
      </div>

      { (isPanelExpanded && activities?.length > 0)
        && (
          <>
            <ul className={classNames.activities}>
              { activities.map(activity => (
                <ActivityListItem
                  key={activity.id}
                  activity={activity}
                />
              ))}
            </ul>

            <div className={classNames.buttonContainer}>
              <Button
                outline
                overrideClassName={classNames.activityProductsButton}
                overrideClassNames={classNames}
                onClick={onClickSeeMacroActivityProducts}
              >
                <FormattedMessage {...messages.SEE_ACTIVITY_PRODUCTS} />
              </Button>
            </div>
          </>
        )}
    </div>
  );
};

MacroActivityCard.displayName = 'MacroActivityCard';

MacroActivityCard.propTypes = {
  macroActivity: macroActivityShape.isRequired,
  activities: PropTypes.arrayOf(activityShape),
  isPanelExpanded: PropTypes.bool,
  onTogglePanel: PropTypes.func,
  onClickSeeMacroActivityProducts: PropTypes.func,
  intl: intlShape.isRequired,
};

MacroActivityCard.defaultProps = {
  isPanelExpanded: false,
  activities: {},
  onTogglePanel: noop,
  onClickSeeMacroActivityProducts: noop,
};

export default enhancer(MacroActivityCard);
