import { defineMessages } from 'react-intl';

export default defineMessages({
  SEE_ACTIVITY_PRODUCTS: { id: 'activities.card.seeProducts', defaultMessage: 'See all products of this macro-activity' },
  TOTAL_PRODUCT_COUNT: {
    id: 'activities.card.products.count',
    defaultMessage: '<div class="count">{count, number}</div> <div class="label">{count, plural, one {product} other {products}}</div>',
  },
  ACTIVITIES_COUNT: {
    id: 'activities.card.activities.count',
    defaultMessage: 'In {count, number} {count, plural, one {activity} other {activities}}',
  },
});
