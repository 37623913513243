import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { noop } from 'lodash';
import cn from 'classnames';
import { formatRoute } from 'react-router-named-routes';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { atomicActivityShape } from 'shapes/businessActivities';
import businessMessages from 'config/business.messages';
import ROUTES from 'react/routes';

import SafeHtml from 'react/generic/html/SafeHtml';

import classNames from './atomicActivityListItem.module.scss';

const withEventHandler = withHandlers({
  onClickSeeAtomicActivityProducts: ({ atomicActivity, history }) => () => {
    const query = `atomicActivityIds=${atomicActivity.id}`;
    history.push(`${formatRoute(ROUTES.PRODUCTS)}?${query}`);
  },
});

const enhancer = compose(
  withRouter,
  withEventHandler,
  memo,
);

const AtomicActivityListItem = ({ atomicActivity, onClickSeeAtomicActivityProducts }) => (
  <li className={classNames.item}>
    <button
      type="button"
      className={cn(classNames.title, 'unstyled with-pointer')}
      onClick={onClickSeeAtomicActivityProducts}
    >
      {atomicActivity.name}
    </button>
    <span className={classNames.count}>
      <button
        type="button"
        className="unstyled with-pointer"
        onClick={onClickSeeAtomicActivityProducts}
      >
        <FormattedMessage
          {...businessMessages.PRODUCTS_COUNT}
          values={{ count: atomicActivity.productCount || 0 }}
        />
      </button>
    </span>
    { atomicActivity.description
      && (
        <SafeHtml unsafeHtml={atomicActivity.description} className={classNames.description} />
      )}
  </li>
);

AtomicActivityListItem.propTypes = {
  atomicActivity: atomicActivityShape.isRequired,
  onClickSeeAtomicActivityProducts: PropTypes.func,
};

AtomicActivityListItem.defaultProps = {
  onClickSeeAtomicActivityProducts: noop,
};

export default enhancer(AtomicActivityListItem);
