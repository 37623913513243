import React, { memo } from 'react';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';

import { NAVIGATION_SHORTCUTS_MESSAGES } from 'config/constants.messages';

import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';
import ApplicationLayout from 'react/business/layout/ApplicationLayout';
import TitleHeader from 'react/business/layout/titleHeader/TitleHeader';

import BusinessActivitiesPage from 'react/pages/businessActivities/BusinessActivitiesPage';

import messages from './business-activities-list.messages';
import classNames from './business-activities-list.module.scss';

const enhancer = compose(
  memo,
);

const BusinessActivitiesList = () => (
  <ApplicationLayout
    mainClassName={classNames.main}
  >
    <FormattedMessageChildren {...messages.TITLE}>
      {message => (
        <Helmet>
          <title>{message}</title>
        </Helmet>
      )}
    </FormattedMessageChildren>
    <TitleHeader>
      <FormattedMessage {...NAVIGATION_SHORTCUTS_MESSAGES.BUSINESS_ACTIVITIES} />
    </TitleHeader>
    <div className={classNames.content}>
      <BusinessActivitiesPage />
    </div>
  </ApplicationLayout>
);

export default enhancer(BusinessActivitiesList);

