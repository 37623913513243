import { compose, lifecycle } from 'recompose';
import connect from 'react/hoc/connectProxy';

import { selectMacroActivitiesMap } from 'redux/businessActivities/selectors';
import { getMacroActivities } from 'redux/businessActivities/actions';

export default compose(
  connect(
    state => ({
      macroActivities: selectMacroActivitiesMap(state),
    }),
    {
      getMacroActivities,
    },
  ),

  lifecycle({
    /**
     * Handle activities loading on mount.
     */
    async componentDidMount() {
      await this.props.getMacroActivities({
        populateActivities: true,
        populateAtomicActivities: true,
        withCount: true,
      });
    },
  }),
);
