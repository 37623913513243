import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { noop } from 'lodash';
import cn from 'classnames';
import { formatRoute } from 'react-router-named-routes';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'antd';

import { activityShape, atomicActivityShape } from 'shapes/businessActivities';
import businessMessages from 'config/business.messages';
import ROUTES from 'react/routes';
import connect from 'react/hoc/connectProxy';
import withExpandState from 'react/hoc/withExpandState';

import AtomicActivityListItem from 'react/business/businessActivities/card/atomicActivityListItem/AtomicActivityListItem';

import { selectAtomicActivitiesListByActivityId } from 'redux/businessActivities/selectors';

import classNames from './activityListItem.module.scss';

const withEventHandler = withHandlers({
  onClickSeeActivityProducts: ({ activity, history }) => () => {
    const query = `activityIds=${activity.id}`;
    history.push(`${formatRoute(ROUTES.PRODUCTS)}?${query}`);
  },
});

const enhancer = compose(
  withRouter,
  withExpandState,
  withEventHandler,

  connect(
    (state, props) => ({
      atomicActivities: selectAtomicActivitiesListByActivityId(state, props.activity.id),
    }),
  ),

  memo,
);

const ActivityListItem = ({
  activity,
  atomicActivities,
  isPanelExpanded,
  onTogglePanel,
  onClickSeeActivityProducts,
}) => (
  <li className={classNames.item}>
    <button
      type="button"
      className={cn(classNames.title, 'unstyled with-pointer')}
      onClick={onTogglePanel}
    >
      <Icon type={isPanelExpanded ? 'up' : 'down'} className={classNames.arrow} />
      {activity.name}
    </button>
    <span className={classNames.count}>
      <button
        type="button"
        className="unstyled with-pointer"
        onClick={onClickSeeActivityProducts}
      >
        <FormattedMessage
          {...businessMessages.PRODUCTS_COUNT}
          values={{ count: activity.productCount || 0 }}
        />
      </button>
    </span>
    {isPanelExpanded && (
      <ul className={classNames.atomicActivitiesList}>
        {
          atomicActivities.map(atomicActivity => (
            <AtomicActivityListItem
              key={atomicActivity.id}
              atomicActivity={atomicActivity}
            />
          ))
        }
      </ul>
    )}
  </li>
);

ActivityListItem.propTypes = {
  activity: activityShape.isRequired,
  atomicActivities: PropTypes.arrayOf(atomicActivityShape),
  isPanelExpanded: PropTypes.bool,
  onTogglePanel: PropTypes.func,
  onClickSeeActivityProducts: PropTypes.func,
};

ActivityListItem.defaultProps = {
  atomicActivities: {},
  isPanelExpanded: false,
  onTogglePanel: noop,
  onClickSeeActivityProducts: noop,
};

export default enhancer(ActivityListItem);
